import React from "react"

import {
  Footer,
  Header,
  Layout,
  SEO,
  SectionBenefitsLinux,
  SectionOfferLinux,
} from "../components"

const LinuxPage = () => (
  <Layout
    header={<Header />}
    footer={(
      <>
        <SectionBenefitsLinux />
        <Footer />
      </>
    )}
  >
    <SEO title={'Linux VPS'} />
    <SectionOfferLinux />
  </Layout>
)

export default LinuxPage
